import request from '@/utils/http'

/**
 * 应用列表
 * @param payload
 */
export function app_list(payload) {
    return request({ url: '/client/Index/index', method: 'post', data: payload })
}

export function lists(payload) {
    return request({ url: '/advs/Advs/lists', method: 'post', data: payload })
}


export function create(payload){
    return request({ url: '/advs/Advs/add', method: 'post', data: payload })
}


export function del(payload){
    return request({ url: '/advs/Advs/delete', method: 'post', data: payload })
}


export function info(payload){
    return request({ url: '/advs/Advs/info', method: 'post', data: payload })
}

export function edit(payload){
    return request({ url: '/advs/Advs/edit', method: 'post', data: payload })
}


export function getRegion(payload){
    return request({ url: '/advs/Advs/getRegion', method: 'post', data: payload })
}